<template>
  <div>
    <title-nav :title="'결제현황'" :nav="'결제관리 / 결제 현황'" />
    <b-container fluid>
      <div class="card">
        <div class="card-header">
          <b-row>
            <b-col cols="4">
              <search @search="item => setCondition(item)"></search>
            </b-col>
            <b-col>
              <b-form-select
                v-model="ordWayCd"
                :options="paymentOptions"
                size="sm"
                @change="changePaymentType"
              >
                <template #first>
                  <b-form-select-option :value="null" disabled>-- 결제수단 --</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <!-- <b-col cols="">
        <b-form-select
          v-model="voucherCd"
          :options="voucherOptions"
          size="sm"
          @change="changePaymentType"
        >
          <template #first>
            <b-form-select-option :value="null" disabled>-- 상품타입 --</b-form-select-option>
          </template>
        </b-form-select>
      </b-col> -->
            <b-col cols="2">
              <b-form-select
                v-model="productCd"
                :options="productOptions"
                size="sm"
                @change="changeProduct"
              >
                <template #first>
                  <b-form-select-option :value="null" disabled>-- 결제상품 --</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col cols="3">
              <b-input-group size="sm">
                <b-input-group-append>
                  <datepicker
                    id="from-datepicker-util"
                    v-model="start_date"
                    v-on:selected="changeStartDate"
                    name="start_date"
                    :value="start_date.date"
                    :format="start_format"
                    placeholder="시작일"
                    input-class="form-control custom-select-sm"
                  />
                  ~
                  <datepicker
                    id="to-datepicker-util"
                    v-model="end_date"
                    v-on:selected="changeEndDate"
                    name="end_date"
                    :value="end_date.date"
                    :format="end_format"
                    placeholder="종료일"
                    input-class="form-control custom-select-sm"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-col>
            <b-col class="d-flex flex-row-reverse"
              ><b-button @click="clear()" size="sm">초기화</b-button></b-col
            >
          </b-row>

          <b-row class="sub-table">
            <b-col>
              <!--상품 -->
              <div class="card">
                <div class="card-header">
                  <h5 class="card-title">상품별</h5>
                </div>
                <div class="card-body p-0">
                  <table class="table table-sm no-border">
                    <tbody>
                      <template v-for="(ele, idx) in totalProd">
                        <tr :key="idx" v-if="!(convertVoucherType(ele.pr_type) == null)">
                          <th v-if="ele.pr_type == 99">
                            <h5><span class="badge bg-danger">총합</span></h5>
                          </th>
                          <th v-else>
                            {{ ele.pr_type_nm }}
                          </th>
                          <td>{{ ele.count }} 건</td>
                          <td>{{ ele.price | commaFilter }} 원</td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </div> </b-col
            ><b-col>
              <!-- 결제수단 -->
              <div class="card">
                <div class="card-header">
                  <h5 class="card-title">결제수단별</h5>
                </div>
                <div class="card-body p-0">
                  <table class="table table-sm no-border">
                    <tbody>
                      <tr v-for="(ele, idx) in total" :key="idx">
                        <th v-if="ele.ord_way_cd == 99">
                          <h5><span class="badge bg-danger">총합</span></h5>
                        </th>
                        <th v-else>
                          {{ ele.ord_way_nm }}
                        </th>
                        <td>{{ ele.count }} 건</td>
                        <td>{{ ele.price | commaFilter }} 원</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="card-body p-0">
          <b-table-simple
            striped
            hover
            bordered
            class="table-sm text-sm-center"
            :borderless="false"
            :dark="false"
            :fixed="true"
            :no-border-collapse="false"
            :head-variant="headVariant"
            :busy="isBusy"
            show-empty
          >
            <b-thead head-variant="dark" class="text-center">
              <b-tr>
                <b-th class="w5"> No</b-th>
                <b-th> PG-NO</b-th>
                <b-th> 결제일자</b-th>
                <b-th>회원번호</b-th>
                <b-th>이메일</b-th>
                <b-th>닉네임</b-th>
                <b-th>이름</b-th>
                <!-- <b-th> 휴대번호</b-th> -->
                <b-th> 결제수단</b-th>
                <b-th> 결제상품</b-th>
                <b-th> 결제금액</b-th>
                <!-- <b-th> 상품명</b-th> -->
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="(row, idx) in result_list" :key="idx">
                <b-td class="w5">{{ row.ord_sn }}</b-td>
                <b-td>{{ row.tno }}</b-td>
                <b-td>{{ row.ord_dt | dateFilter }}</b-td>
                <b-td
                  ><span
                    ><router-link
                      :to="{ name: 'detail', params: { usersn: row.user_sn } }"
                      variant="link"
                      v-if="row.user_stat_cd === 'TEMP' || row.user_stat_cd === 'NORMAL'"
                      >{{ row.user_sn }}</router-link
                    >
                    <p v-else>{{ row.user_sn }}</p></span
                  ></b-td
                >
                <b-td>{{ row.email }}</b-td>
                <b-td
                  ><span>{{ row.user_name }}</span></b-td
                >
                <b-td
                  ><span>{{ row.real_name }}</span></b-td
                >
                <!-- <b-td><span>-</span></b-td> -->
                <b-td
                  ><span>{{ convertPaymentType(row.ord_way_cd) }}</span></b-td
                >
                <b-td>{{ row.title }}</b-td>
                <b-td>{{ row.price | commaFilter }}</b-td>
                <!-- <b-td>-</b-td> -->
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
        <div class="card-footer clearfix">
          <b-pagination
            v-model="curPage"
            :total-rows="records"
            :per-page="rowPerPage"
            @change="setPage"
            align="right"
          ></b-pagination>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import Search from '../util/Search'
import { getPaymentHistory } from '@/api/payment'

import { getPaymentType, getVoucherType } from '../../api/code'
import Datepicker from 'vuejs-datepicker/dist/vuejs-datepicker.esm.js'
import { getProducts } from '../../api/voucher'
var now = new Date()
var monthFirstDate = new Date(now.getUTCFullYear(), now.getMonth(), 1)
var monthLastDate = new Date(now.getUTCFullYear(), now.getMonth() + 1, 0)

export default {
  name: 'Payment',
  components: {
    Search,
    Datepicker
  },
  data() {
    return {
      start_date: this.$moment(monthFirstDate).format('YYYY/MM/DD'),
      end_date: this.$moment(monthLastDate).format('YYYY/MM/DD'),
      start_format: 'yyyy/MM/dd',
      end_format: 'yyyy/MM/dd',
      striped: true,
      bordered: true,
      borderless: false,
      small: true,
      hover: true,
      dark: false,
      fixed: false,
      isBusy: false,
      noCollapse: false,
      headVariant: 'dark',
      total_price: 0,
      total_cnt: 0,
      result_list: [],
      search: '',
      curPage: 1,
      rowPerPage: 20,
      records: 0,
      usersn: '',
      email: '',
      user_name: '',
      PAYMENT_CODE: [],
      VOUCHER_CODE: [],
      PRODUCT_CODE: [],
      ordWayCd: null,
      results: [],
      paymentOptions: [],
      voucherOptions: [],
      productOptions: [],
      voucherCd: null,
      productCd: null,
      totalProd: [],
      total: []
    }
  },
  methods: {
    changePaymentType() {
      this.init(this.start_date, this.end_date)
    },
    changeProduct() {
      this.init(this.start_date, this.end_date)
    },
    convertPaymentType(code) {
      if (code) {
        if (code == 99) return '총합'
        const name = this.PAYMENT_CODE.find(value => value.cd_value === code)?.cd_name_kr
        if (!name) {
          return null
        }
        return name
      } else return 'paymentType error'
    },

    async initPaymentType() {
      var resp = await getPaymentType()
      this.PAYMENT_CODE = resp
      resp.map((item, idx) => {
        if (idx > 0) this.paymentOptions.push({ value: item.cd_value, text: item.cd_name_kr })
      })
    },

    async initVoucherType() {
      var resp = await getVoucherType()
      this.VOUCHER_CODE = resp
      resp.map((item, idx) => {
        if (idx > 0) this.voucherOptions.push({ value: item.cd_value, text: item.cd_name_kr })
      })
    },
    async initProductList() {
      var resp = await getProducts()
      console.log(resp)
      const data1 = resp.data.filter(e => e.pr_stat_cd === 'NORMAL')
      const data2 = resp.data.filter(e => e.pr_stat_cd !== 'NORMAL')
      const temp = [...data1, ...data2]
      temp.map((item, idx) => {
        if (idx > 0)
          this.productOptions.push({
            value: item.pr_sn,
            text: item.title + ', ' + item.pr_stat_cd,
            stat: item.pr_stat_cd
          })
      })
    },
    convertVoucherType(code) {
      if (code) {
        if (code == 99) return '총합'
        const name = this.VOUCHER_CODE.find(value => value.cd_value === code)?.cd_name_kr
        if (!name) {
          return null
        }
        return name
      } else return 'code is empty'
    },
    convertDateFormat(date) {
      return this.$moment(date).format('YYYY/MM/DD')
    },

    changeStartDate(val) {
      this.start_date = this.$moment(val).format('YYYY/MM/DD')
      // this.end_date = this.$moment(this.end_date).format('YYYYMMDD')
      this.curPage = 1
      this.init(this.start_date, this.end_date)
    },
    changeEndDate(val) {
      // this.start_date = this.$moment(this.start_date).format('YYYYMMDD')
      this.end_date = this.$moment(val).format('YYYY/MM/DD')
      this.curPage = 1
      this.init(this.start_date, this.end_date)
    },
    setCondition(search) {
      this.usersn = this.email = ''
      switch (search.key) {
        case 'usersn':
          this.usersn = search.value
          break
        case 'email':
          this.email = search.value
          break
        case 'name':
          this.name = search.value
          break
        case 'referID':
          this.email = search.value
          break
      }
      this.curPage = 1
      this.search = search
      this.init(this.start_date, this.end_date)
    },
    clear() {
      location.reload()
      // this.curPage = 1
      // this.rowPerPage = 20
      // this.search = ''
      // this.ordWayCd = null
      // this.productCd = null
      // this.start_date = this.$moment(monthFirstDate).format('YYYY/MM/DD')
      // this.end_date = this.$moment(monthLastDate).format('YYYY/MM/DD')
      // this.init(this.start_date, this.end_date)
    },
    setPage(page) {
      this.curPage = page
      this.result_list = []
      this.init(this.start_date, this.end_date)
    },
    async init(startDate = monthFirstDate, endDate = monthLastDate) {
      try {
        startDate = this.$moment(startDate).format('YYYYMMDD')
        endDate = this.$moment(endDate).format('YYYYMMDD')
        // console.log(startDate, endDate)
        this.total_price = 0
        this.total_cnt = 0
        const resp = await getPaymentHistory(
          this.curPage,
          this.rowPerPage,
          this.search.key,
          this.search.value,
          startDate,
          endDate,
          this.ordWayCd,
          this.productCd
        )
        if (resp.code === '0000') {
          this.result_list = resp.data.list
          this.total = resp.data.total
          this.total_cnt = resp.data.total.count
          this.total_price = resp.data.total.price
          this.totalProd = resp.data.totalProd
          this.records = resp.data.totCnt
          this.summery(resp.data.list)
        } else {
          alert(`error:${resp.code}`)
        }
      } catch (error) {
        alert(`error:${error}`)
      }
    },
    summery(data) {
      this.results = []
      /**
       *  myData = [
        {
          date: '20211104',
          ord_way_list: [
            { ord_way_cd: '02', price: 40200 },
            { ord_way_cd: '02', price: 40200 },
            { ord_way_cd: '02', price: 40200 }
          ]
        }
      ]
       */

      // console.log(`data`, data)
      data.map((item, idx) => {
        if (idx) {
          var dt = this.$moment(item.ord_dt)
            .utc()
            .format('YYYYMMDD')
          // console.log('item', item)
          if (this.hasDateValue(this.results, dt)) {
            var ordWayList = []
            this.results.map(ordItem => {
              if (ordItem.date == dt) {
                ordWayList = ordItem.ord_way_list
                return
              }
            })
            // console.log(`ordWayList`, dt, ordWayList, this.hasOrdWayCd(ordWayList, item.ord_way_cd))
            if (this.hasOrdWayCd(ordWayList, item.ord_way_cd)) {
              // console.log('ordWayList ', ordWayList)
              ordWayList.map(ordWayRow => {
                if (ordWayRow.ord_way_cd == item.ord_way_cd) {
                  ordWayRow.price += item.price
                }
              })
            } else {
              // console.log(`item:`, item)
              ordWayList.push({ ord_way_cd: item.ord_way_cd, price: item.price })
            }
          } else {
            // console.log(`push date`, dt)
            this.results.push({
              date: dt,
              ord_way_list: [{ ord_way_cd: item.ord_way_cd, price: item.price }]
            })
          }
        }
      })
    },
    hasDateValue(list, value) {
      // console.log(`hasDateValue`, value)
      if (list) return list.some(item => item.date == value)
      else return null
    },
    hasOrdWayCd(list, code) {
      if (list) return list.some(item => item.ord_way_cd == code)
      return null
    }
  },
  mounted() {
    this.initVoucherType()
    this.initPaymentType()
    this.initProductList()
    this.init()
  }
}
</script>

<style scoped>
.myTable {
  width: 100%;
  height: 100%;
  border: 1px solid grey;
}
.myTable th {
  width: 15%;
}
.myTable td {
  width: 25%;
}
th,
td {
  border: 1px solid slategray;
}
</style>
